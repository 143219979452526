/****************************
PRINCIPAL COLOR
****************************/

$color: #93c051;

/****************************
RADIO GROUP
****************************/
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $color;
  border-color: $color;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: lighten($color, 10);
  border-color: lighten($color, 10);
}

.ant-radio-button-wrapper:hover {
  color: $color;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $color;
}

.ant-radio-group-outline .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $color;
}
.ant-radio-group-outline .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-group-outline .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: lighten($color, 10);
  border-color: lighten($color, 10);
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: unset;
}

/****************************
SWITCH
****************************/
.ant-switch {
  background-color: #f6322d;
}
.ant-switch-checked {
  background-color: #00dd0f;
}

/****************************
TABLE
****************************/

.ant-table-content {
  display: inline-block;
  width: 100%;
}

.ant-table-small {
  border: 0;
}

.expiratedContract {
  background: #e50000 !important;
}

.expiratedContract:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #e50000 !important;
}
.desactiveContact {
  background: #fcc !important;
}

.desactiveContact:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #fcc !important;
}

/****************************
ERROR
****************************/

.error {
  textarea {
    background: #fcc;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fcc;
  }
  .ant-picker,
  .ant-select {
    background: #fcc;
    input {
      background-color: unset !important;
      &::placeholder {
        color: #0000009c;
      }
    }
  }
  .ant-select-clear:hover,
  .ant-picker-clear:hover {
    color: #0000009c;
  }
  .ant-select-clear,
  .ant-picker-clear {
    border-radius: 7px;
    background: #ffffff;
    color: #000;
  }
}

/****************************
PAGINATION
****************************/

$lightenOrange: #fbbd4d;

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $lightenOrange;
}

.ant-pagination-prev:not(.ant-pagination-disabled) > a > i > svg,
.ant-pagination-next:not(.ant-pagination-disabled) > a > i > svg {
  color: #888;
  font-weight: bold;

  &:hover {
    color: $lightenOrange;
    transform: scale(1.1);
  }
}

.ant-pagination-prev:not(.ant-pagination-disabled):focus .ant-pagination-item-link,
.ant-pagination-next:not(.ant-pagination-disabled):focus .ant-pagination-item-link,
.ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
.ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
  color: #fff;
  font-weight: bold;
  background: $lightenOrange;
  border-color: $lightenOrange;
}

.ant-pagination-item {
  &:focus,
  &:hover {
    a {
      color: $lightenOrange;
    }
  }

  a {
    color: #888;
    font-weight: bold;

    &:hover,
    &:focus {
      color: $lightenOrange;
      transform: scale(1.06);
    }
  }
}
.ant-pagination-item-active,
.ant-pagination-item-active:active,
.ant-pagination-item-active:focus {
  border-color: $lightenOrange;

  a {
    color: $lightenOrange;
  }

  &:hover {
    border-color: $lightenOrange;
    background: $lightenOrange;

    a {
      color: #fff;
    }
  }
}

.ant-pagination-total-text {
  color: $lightenOrange;
}

form {
  h3 {
    color: #888;
    text-transform: uppercase;
    margin: 15px 8px !important;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
  }

  .ant-row h3 {
    margin: 15px 8px 5px;
  }
}

/****************************
POPOVER
****************************/

.ant-popover {
  .ant-popover-content {
    border: 1px solid #a1a1a1;
    border-radius: 6px;

    .ant-popover-arrow {
      border-bottom: 1px solid #a1a1a1;
      border-right: 1px solid #a1a1a1;
      z-index: 999999;
      background: #fff;
    }
  }

  .ant-btn-primary {
    color: #fff;
    background-color: #e50000;
    border-color: #e50000;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
}

/****************************
ANTD TAG
****************************/

.ant-tag {
  min-width: 80px;
  text-align: center;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: 2px solid #93c051;
}
